@import "variables";

// Start Buttons Merieux

.btn {
  --bs-btn-disabled-color: white;
  --bs-btn-disabled-bg: grey;
  --bs-btn-disabled-border-color: grey;
  --bs-btn-disabled-opacity: 0.65;

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    pointer-events: auto;
    cursor: not-allowed;
  }

  :not(.btn-check) + &:hover:disabled,
  &:first-child:hover:disabled {
    color: white;
    background-color: grey;
    border-color: grey;
  }
}

.btn-merieux-light-blue {
  color: white;
  background-color: $color-merieux-light-blue;
  padding: 8px 10px;
  margin: 3px;
  font-weight: 400;
  border-width: 2px 2px 2px 2px;
  border-color: $color-merieux-light-blue;
  border-radius: unset !important;

  :not(.btn-check) + &:hover,
  &:first-child:hover {
    color: white;
    background-color: $color-merieux-extra-light-blue;
    border-color: $color-merieux-extra-light-blue;
  }
}

.btn-merieux-white {
  color: #3e3f3a;
  background-color: white;
  padding: 8px 10px;
  margin: 3px;
  font-weight: 400;
  border-width: 2px 2px 2px 2px;
  border-color: white;
  border-radius: unset !important;

  :not(.btn-check) + &:hover,
  &:first-child:hover {
    color: #3e3f3a;
    background-color: #efefef;
    border-color: #efefef;
  }
}

.btn-merieux {
  width: max-content;
  color: white;
  padding: 8px 10px 8px 10px;
  margin: 3px;
  font-weight: 400;
  border-width: 2px 2px 2px 2px;
  border-radius: unset !important;

  :not(.btn-check) + &:hover,
  &:first-child:hover {
    color: white;
  }
}

.input-btn-merieux {
  padding: 8px 10px 8px 10px;
  border-width: 2px 2px 2px 2px;
  border-radius: unset !important;

  :not(.btn-check) + &:hover,
  &:first-child:hover {
    color: white;
  }
}

.btn-merieux-green {
  background-color: $color-merieux-green !important;
  border-color: $color-merieux-green !important;

  :not(.btn-check) + &:hover,
  &:first-child:hover {
    color: white !important;
    background-color: #64c43b !important;
    border-color: #64c43b !important;
  }
}

.btn-merieux-dark-green {
  background-color: $color-merieux-dark-green !important;
  border-color: $color-merieux-dark-green !important;

  :not(.btn-check) + &:hover,
  &:first-child:hover {
    color: white !important;
    background-color: #298A4F !important;
    border-color: #298A4F !important;
  }
}

.btn-merieux-red {
  background-color: #E63B0B !important;
  border-color: #E63B0B !important;

  :not(.btn-check) + &:hover,
  &:first-child:hover {
    color: white !important;
    background-color: #F23E0C !important;
    border-color: #F23E0C !important;
  }
}

.btn-merieux-blue {
  background-color: $color-merieux-blue !important;
  border-color: $color-merieux-blue !important;

  :not(.btn-check) + &:hover,
  &:first-child:hover {
    color: white !important;
    background-color: #0C5496 !important;
    border-color: #0C5496 !important;
  }
}

.btn-merieux-xl-blue {
  color: white;
  background-color: $color-merieux-blue;
  padding: 25px 40px;
  margin: 3px 0;
  font-size: 1rem;
  font-weight: 600;
  border-width: 2px 2px 2px 2px;
  border-color: $color-merieux-blue;
  border-radius: unset !important;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;

  :not(.btn-check) + &:hover,
  &:first-child:hover {
    color: white;
    background-color: #0C5496;
    border-color: #0C5496;
  }
}

.btn-merieux-xl-red {
  color: white;
  background-color: $color-merieux-red;
  padding: 25px 40px;
  margin: 3px 0;
  font-size: 1rem;
  font-weight: 600;
  border-width: 2px 2px 2px 2px;
  border-color: $color-merieux-red;
  border-radius: unset !important;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;

  :not(.btn-check) + &:hover,
  &:first-child:hover {
    color: white;
    background-color: #E63B0B;
    border-color: #E63B0B;
  }
}

.btn-light-blue {
  color: white;
  background-color: $color-merieux-light-blue;
  border-color: $color-merieux-light-blue;

  :not(.btn-check) + &:hover,
  &:first-child:hover {
    color: white;
    background-color: $color-merieux-extra-light-blue;
    border-color: $color-merieux-extra-light-blue;
  }
}

.btn-cyan {
  color: white;
  background-color: $color-merieux-cyan;
  border-color: $color-merieux-cyan;

  :not(.btn-check) + &:hover,
  &:first-child:hover {
    color: white;
    background-color: $color-merieux-extra-light-cyan;
    border-color: $color-merieux-extra-light-cyan;
  }
}

.btn-red {
  color: white;
  background-color: $color-merieux-red;
  border-color: $color-merieux-red;

  :not(.btn-check) + &:hover,
  &:first-child:hover {
    color: white;
    background-color: $color-merieux-extra-light-red;
    border-color: $color-merieux-extra-light-red;
  }
}

.btn-orange {
  color: white;
  background-color: $color-merieux-orange;
  border-color: $color-merieux-orange;

  :not(.btn-check) + &:hover,
  &:first-child:hover {
    color: white;
    background-color: $color-merieux-extra-light-orange;
    border-color: $color-merieux-extra-light-orange;
  }
}

.btn-pink {
  color: white;
  background-color: $color-merieux-pink;
  border-color: $color-merieux-pink;

  :not(.btn-check) + &:hover,
  &:first-child:hover {
    color: white;
    background-color: $color-merieux-extra-light-pink;
    border-color: $color-merieux-extra-light-pink;
  }
}

.btn-yellow {
  color: white;
  background-color: $color-merieux-yellow;
  border-color: $color-merieux-yellow;

  :not(.btn-check) + &:hover,
  &:first-child:hover {
    color: white;
    background-color: $color-merieux-extra-light-yellow;
    border-color: $color-merieux-extra-light-yellow;
  }
}

.btn-green {
  color: white;
  background-color: $color-merieux-green;
  border-color: $color-merieux-green;

  :not(.btn-check) + &:hover,
  &:first-child:hover {
    color: white;
    background-color: $color-merieux-extra-light-green;
    border-color: $color-merieux-extra-light-green;
  }
}

.btn-gray {
  color: white;
  background-color: #444;
  border-color: #444;

  :not(.btn-check) + &:hover,
  &:first-child:hover {
    color: white;
    background-color: #4d4d4d;
    border-color: #4d4d4d;
  }
}

.btn-merieux-small {
  padding: 4px 8px !important;
  font-size: smaller;
  text-transform: unset;
}

.btn-merieux-medium {
  padding: 13px 15px !important;
  font-size: medium;
}

.btn-merieux-large {
  padding: 18px 20px !important;
  font-size: medium;
}

.btn-sm-merieux {
  padding: 0.25rem 0.5rem;
  border-radius: 0.2rem;
}

// Media
@media (max-width: 991.98px) {
  .btn-merieux-xl-blue,
  .btn-merieux-xl-red {
    padding: 20px 12px;
    font-size: 1rem;
    font-weight: 600;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
  }
}

// End Buttons Merieux

// Start Border Merieux
.merieux-border {
  border: 4px solid #dadada;
  border-radius: 0;
  background: white;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
}

.merieux-sub-border {
  border: 3px solid #dadada !important;
  border-radius: 0 !important;
}

// End Border Merieux

// Start OK/NOK Switch
.m-switch-radio-button {
  font-weight: 500;
  text-transform: uppercase;

  .switch {
    width: 100px;
    height: 33px;
    text-align: center;
    background: #8e8c84;
    transition: all 0.2s ease;
    border-radius: 16px;
  }

  input[type=radio] {
    display: none;
  }

  .switch label {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.5);
    width: 40px;
    line-height: 33px;
    transition: all 0.2s ease;
    font-size: 12px;
  }

  label[for^="yes-"] {
    position: relative;
    left: 0;
    height: 13px;
  }

  label[for^="no-"] {
    position: relative;
    left: -6px;
  }

  [id^="yes-"]:checked ~ .switch {
    background: $color-merieux-green;
  }

  [id^="no-"]:checked ~ .switch {
    background: #E63B0B;
  }

  [id^="yes-"]:checked ~ .switch label[for^="yes-"],
  [id^="no-"]:checked ~ .switch label[for^="no-"] {
    color: #fff;
  }
}

// END OK/NOK Switch

// Start YES/NO/NA Switch Overrides
.y-n-n-radio {

  .switch {
    width: 150px;
  }

  label[for^="na-"] {
    position: relative;
    left: -6px;
  }

  [id^="na-"]:checked ~ .switch {
    background: $color-merieux-light-blue;

    label[for^="na-"] {
      color: #fff;
    }
  }
}

// END YES/NO/NA Switch Overrides

// Start Merieux Asterix
i.text-primary {
  font-size: 9px !important;
  position: relative;
  top: -4px;
  color: $color-merieux-cyan !important;
}

// End Merieux Asterix

// Start Switch Sizing

.form-switch.form-switch-sm .form-check-input {
  height: 1rem;
  width: calc(1rem + 0.75rem);
  border-radius: 2rem;
}

.form-switch.form-switch-md .form-check-input {
  height: 1.5rem;
  width: calc(2rem + 0.75rem);
  border-radius: 3rem;
}

.form-switch.form-switch-lg .form-check-input {
  height: 2rem;
  width: calc(3rem + 0.75rem);
  border-radius: 4rem;
}

.form-switch.form-switch-xl .form-check-input {
  height: 2.5rem;
  width: calc(4rem + 0.75rem);
  border-radius: 5rem;
}

// End Switch Sizing
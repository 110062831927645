/**
  scss variable overwrite for Bootstrap and Sandstone
 */

// Overwriting link color in Sandstone
$link-color: #737373 !default;
$font-size-base: 0.9rem;

// Overwriting position utilities
$position-values: (
        0: 0,
        50: 50%,
        90: 90%,
        100: 100%
);

// New variables
$color-merieux: rgba(0, 34, 85, 1);

$form-feedback-valid-color: #3e3f3a;

// Utility classes

$primary-color: #3399dd;
$secondary-color: #292a2c;

.navbar-merieux {
  background-color: $secondary-color !important;

  .company-logo {
    max-width: 70%;
    height: auto;
    padding-left: 2px;
    padding-top: 5px;
  }

  button.btn.btn-info {
    font-size: 11px;
  }

  .navbar-nav {
    .dropdown-toggle::after,
    a {
      color: $white;
    }

    .dropdown-item {
      color: $secondary-color;
    }

    .nav-link {
      text-transform: none;
      color: $white;
      font-weight: normal;
      font-size: 0.8rem;

      i {
        font-size: 20px;
        padding-left: 0.3rem;
      }

      &:hover {
        color: $white;
      }
    }
  }
}

// Media

@media (max-width: 1200px) {
  .navbar-merieux {
    button.btn.btn-info {
      font-size: 10px;
    }

    .navbar-nav {
      .nav-link {
        font-size: 12px;

      }
    }
  }
}

@media (max-width: 991.98px) {
  .navbar-merieux {
    .company-logo {
      display: none;
    }

    .navbar-nav {
      .nav-link {
        padding-left: 10px;
        padding-right: 5px;

        i {
          font-size: 17px;
        }
      }
    }
  }
}

// mouse hovered item

$color-merieux-light-blue: #00abe6;

.select2-selection__choice:hover {
  background-color: #ccc;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-radius: 0;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-radius: 0;
}

.input-group-text {
  border-radius: 0;
}

.select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
  border-radius: 0;
}

.select2-selection {
  border: 1px solid #dadada;
  border-radius: unset !important;

  &:focus {
    border-color: #dadada !important;
  }
}

.select2-selection--single {
  overflow: hidden !important;
  height: auto !important;
}

span.select2-selection.select2-selection--single {
  border-color: #dadada;
}

.select2-container--default .select2-selection--multiple {
  border: 1px solid #dadada;
  padding-bottom: 7px;
}

.form-control {
  border: 1px solid #dadada;
  border-radius: unset !important;

  &:focus {
    border-color: #dadada !important;
  }
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  border-radius: unset !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 6px;
  padding-right: 10px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 34px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow,
.select2-container--default .select2-selection--single .select2-selection__clear {
  height: 34px;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder,
.select2-container--default .select2-search--inline .select2-search__field {
  padding-left: 4px;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #f8f5f0;
}

.select2-container--bootstrap-5 .select2-selection--single .select2-selection__clear:hover {
  filter: brightness(0%);
}

label.form-label,
.label-as-form-label {
  font-size: 14px;
  font-weight: 500;
  padding-left: 5px;
  margin-bottom: 0.1rem;
}

.label-as-header {
  label.form-label,
  .label-header {
    font-size: 1.125rem !important;
    font-weight: 700 !important;
    padding-bottom: 5px;
    padding-left: 5px;
    margin-bottom: 0.1rem;
  }
}

.form-select {
  border-radius: unset !important;
  border: 1px solid #dadada;
}

.form-check-input:checked {
  background-color: $color-merieux-light-blue;
  border-color: $color-merieux-light-blue;
}

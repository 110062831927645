/**
  css overwrite and content which is available on the entire application
  Module or webpage specific css should get their own file and/or endpoint
 */
@use "sass:color";
@import "variables";

.navbar {
  background-color: #e7e7e7;
  padding: 8px 16px 8px 16px;
}

body {
  padding-top: 60px;
  margin-bottom: 50px;
}

.container-fluid {
  padding-bottom: 100px;
  padding-right: unset;
  padding-left: unset;
}

.top-gutter {
  position: relative;
  top: -4px;
}

.top-no-spacing {
  position: relative;
  top: -11px;
}

h5.title-box, .title-box.h5 {
  padding: 20px 20px 15px;
  background-color: $color-merieux-blue;
  color: #ffffff;
  -webkit-font-smoothing: antialiased;
}

.title-box-absolute {
  position: fixed;
  top: 80px;
  left: 95px;
  -webkit-font-smoothing: antialiased;
  z-index: 810;
}

.container-fluid.bo-container {
  margin-top: -3px;
}

.bo-container {
  padding-bottom: 0;
}

.bo-sidebar {
  background-color: #325d88;
  color: #fff;
}

.bo-sidebar .nav-link {
  color: #fff;
  font-size: 15px;
}

.bo-submenu {
  background-color: color.adjust(#325d88, $lightness: -10%);

}

.bo-submenu .nav-link {
  font-size: 12px;
}

.nav-container {
  padding: 0;
}

a {
  text-decoration: none;
}

a:hover {
  color: #000000;
}

.logo-container {
  max-width: 200px;
  height: auto;
}

.card-header {
  padding: .75rem 1.25rem;
}

.card-title {
  margin-bottom: 0;
}

.card-min-height {
  min-height: 400px;
  margin-bottom: 10px;
}

.card.card-min-height {
  border: none !important;
}

.badge-danger {
  background-color: $danger;
}

h6 {
  font-weight: bold;
}

.primary-background {
  background-color: #325d88;
}

.form-switch .form-check-input {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='rgba(50, 93, 136, 0.75)'/></svg>");
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-move {
  cursor: move;
}

.cursor-help {
  cursor: help;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.statusbar {
  position: fixed;
}

.statusbar .enabled:hover {
  font-weight: bold;
  cursor: pointer;
}

.alert a, .alert .alert-link .btn {
  text-decoration: none;
}

.toastui-chart-tooltip-container {
  max-width: 300px;
  position: absolute;
  z-index: 1;
}

.dropdown-menu {
  z-index: 1032;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #d9534f !important;
}

.invalid-feedback {
  font-size: 12px;
}

.list-group-item {
  border: 1px solid #dfd7ca;
  padding: 0.5rem 1rem;
}

.modal {
  .modal-content {
    border: 1px solid $secondary-color;
    border-radius: unset;
  }

  .modal-body form,
  .modal-footer {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .modal-header {
    border-bottom: 1px solid $secondary-color;
    border-top-left-radius: unset;
    border-top-right-radius: unset;
  }

  .modal-footer {
    border-top: none;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
  }
}


/**
  Custom classes
 */

.text-custom {
  color: $color-merieux;
}

.custom-background {
  background-color: $color-merieux;
}

.custom-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mw-fitcontent {
  max-width: fit-content;
}

.sidebar-menu-gutter {
  padding-left: 75px;
}

.m-btn {
  margin: 1rem;
}

.font-size-initial {
  font-size: initial;
}

.font-size-small {
  font-size: small;
}

.font-size-x-small {
  font-size: x-small;
}

.font-size-xx-small {
  font-size: xx-small;
}

.font-size-large {
  font-size: large;
}

.font-size-medium {
  font-size: medium;
}

.font-size-x-large {
  font-size: x-large;
}

.font-size-xx-large {
  font-size: xx-large;
}

.font-size-1rem {
  font-size: 1rem;
}

.box-text {
  font-size: 14px;
  font-weight: 500;
}

.header-info,
.box-text-sm {
  font-size: 10px;
  font-weight: 500;
}

.responsive {
  width: 100%;
  height: auto;
}

.link-box {
  max-width: 100%;
  min-height: 60px;
}

.link-logo {
  width: auto;
  height: 37px;
  line-height: normal;
  position: absolute;
  left: 10px;
  top: 10px;
}

.center-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.w-0 {
  width: 0;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-40 {
  width: 40% !important;
}

.w-90 {
  width: 90% !important;
}

.h-35px {
  height: 35px;
  overflow-y: hidden;
}

.bottom-5px {
  position: relative;
  bottom: 5px;
}

.top-10px {
  position: relative;
  top: 10px;
}

.up-10 {
  position: relative;
  top: -10px;
}

.sub-header {
  font-size: 1.125rem !important;
  font-weight: 700 !important;
  padding-bottom: 5px;
  padding-left: 5px;
  margin-bottom: 0.1rem;
}

.fixed-head {
  position: absolute;
  top: 50px;
  left: 12px;
  width: 100%;
  height: 100px;
  display: block;
  overflow: hidden;
  z-index: -1;
}

.form-control-disabled {
  background-color: #f8f5f0;
  opacity: 1;
}

.fw-500 {
  font-weight: 500;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.circle-bg {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 31px;
  vertical-align: middle;
  border-radius: 50%;
  margin-bottom: 2px;
}

.border-dashed {
  border-style: dashed !important;
}

.width-max-content {
  width: max-content;
}

.list-clean ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.opacity-90 {
  opacity: .9;
}

.btn-trans {
  border: none;
  background-color: transparent;

  &:hover {
    color: $color-merieux-light-blue;
  }
}

.border-color-merieux {
  border-color: #dadada !important;
}

.bg-opacity-90 {
  --bs-bg-opacity: 0.90;
}

// Drag&drop
.dd-container {
  margin-top: 0.2rem;
  min-height: 50px;
}

.dd-container-gutter {
  padding: 0.3rem;
}

.dd-item {
  padding: 0.45rem 1rem 0.45rem;
  background-color: #f9f9f9;
  border: 2px solid white;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
}

.my-handle {
  cursor: move;
  padding-right: 2rem;
}

.list-clean ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.general-dasboard {
  .offcanvas-end {
    width: 50% !important;
  }

  .offcanvas {
    .btn-close {
      background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    }
  }
}

.select2-container--default.select2-container--disabled .select2-selection--single,
:disabled, .disabled {
  cursor: not-allowed !important;
}

// Media
@media (min-width: 992px) {
  .header-info,
  .box-text-sm {
    font-size: 14px;
  }
}

.text-initial {
  text-transform: unset !important;
  font-weight: normal !important;
}

@media (max-width: 1199px) {
  h2 {
    font-size: 1.5em;
  }
  .w-75-below-xxl {
    width: 75% !important;
  }
}

@media (max-width: 991.98px) {
  .font-md-x-small {
    font-size: x-small;
  }
}
/**
  Calls all the css dependencies for the entire application
  Webpage or module specific css should not be added here.
 */

@import "./helper/variables";
@import "bootswatch/dist/sandstone/_variables.scss";
@import "bootstrap";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/maps";
@import "bootswatch/dist/sandstone/bootswatch";
@import "select2";
@import "select2/src/scss/core";

/**
  @see https://github.com/apalfrey/select2-bootstrap-5-theme/issues/75#issuecomment-1416307961
  Temporary fix exactly here to cope with Bootstrap 5.3 compatibility issue
  Github author of select2-bootstrap-5-theme doesn't seem really active
  Setting var here inherits the $border-color from select2 core and is used in include-all
 */
$s2bs5-border-color: $border-color;

@import "select2-bootstrap-5-theme/src/include-all";
@import "utilities";
@import "./select2";
@import "./general-style-overwrite";
@import "sidebarMenu";
@import "navMerieux";
@import "toolTip";
@import "elementsMerieux";

@import "bootstrap-icons/font/bootstrap-icons.css";




@import "variables";

.tooltip.show {
  opacity: 1;
}

.tooltip-inner {
  text-transform: none;
  font-size: 0.9rem !important;
  font-weight: normal;
  border-radius: unset;
  max-width: 300px;
  padding: 10px 15px;
  background-color: $secondary-color;
  opacity: 1 !important;
}

.tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: $secondary-color;
}

.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: $secondary-color;
}

.tooltip.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: $secondary-color;
}

.tooltip.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: $secondary-color;
}

.tooltip-color .tooltip-inner {
  background-color: $color-merieux-green !important;
}

.tooltip-top-arrow-color .tooltip-arrow::before {
  border-top-color: $color-merieux-green !important;
}

.tooltip-bottom-arrow-color .tooltip-arrow::before {
  border-bottom-color: $color-merieux-green !important;
}

.tooltip-left-arrow-color .tooltip-arrow::before {
  border-left-color: $color-merieux-green !important;
}

.tooltip-right-arrow-color .tooltip-arrow::before {
  border-right-color: $color-merieux-green !important;
}
// Colors
$primary-color: #3399dd;
$secondary-color: #292a2c;
$color-light-grey: #efefef !important;
$body-color: var(--bs-body-color);

// Merieux colors
$color-merieux-blue: #0a4479;
$color-merieux-light-blue: #00abe6;
$color-merieux-cyan: #06889d;
$color-merieux-green: #55a932;
$color-merieux-dark-green: #00742d;
$color-merieux-light-green: #afcc46;
$color-merieux-yellow: #f1c232;
$color-merieux-purple: #803883;
$color-merieux-red: #b32c1b;
$color-merieux-pink: #ba327d;
$color-merieux-orange: #d28f1b;

// Extra light Merieux colors
$color-merieux-extra-light-blue: #00b6f2;
$color-merieux-extra-light-red: #c02f1d;
$color-merieux-extra-light-orange: #de971d;
$color-merieux-extra-light-pink: #c53584;
$color-merieux-extra-light-green: #5bb435;
$color-merieux-extra-light-yellow: #FECF3F;
$color-merieux-extra-light-cyan: #20A2B7;
$color-merieux-extra-light-gray: #f9f9f9;

.body-color {
  color: $body-color;
}

.color-merieux-blue {
  color: $color-merieux-blue;
}

.color-merieux-light-blue {
  color: $color-merieux-light-blue;
}

.color-merieux-cyan {
  color: $color-merieux-cyan;
}
.color-merieux-green {
  color: $color-merieux-green;
}

.color-merieux-dark-green {
  color: $color-merieux-dark-green;
}

.color-merieux-light-green {
  color: $color-merieux-light-green;
}

.color-merieux-yellow {
  color: $color-merieux-yellow;
}

.color-merieux-purple {
  color: $color-merieux-purple;
}

.color-merieux-red {
  color: $color-merieux-red;
}

.color-merieux-pink {
  color: $color-merieux-pink;
}

.color-merieux-orange {
  color: $color-merieux-orange;
}

.color-merieux-dark-grey {
  color: $secondary-color;
}

.color-link-white a {
  color: white;
}

.bg-merieux-blue {
  background-color: $color-merieux-blue;
}

.bg-merieux-light-blue {
  background-color: $color-merieux-light-blue !important;
}

.bg-merieux-cyan {
  background-color: $color-merieux-cyan;
}
.bg-merieux-green {
  background-color: $color-merieux-green;
}

.bg-merieux-dark-green {
  background-color: $color-merieux-dark-green;
}

.bg-merieux-light-green {
  background-color: $color-merieux-light-green;
}

.bg-merieux-yellow {
  background-color: $color-merieux-yellow;
}

.bg-merieux-purple {
  background-color: $color-merieux-purple;
}

.bg-merieux-red {
  background-color: $color-merieux-red;
}

.bg-merieux-pink {
  background-color: $color-merieux-pink;
}

.bg-merieux-orange {
  background-color: $color-merieux-orange;
}

.bg-light-grey {
  background-color: $color-light-grey;
}

.bg-border-light-grey {
  background-color: $color-light-grey;
  border-color: $color-light-grey;
}

.bg-extra-light-grey {
  background-color: $color-merieux-extra-light-gray;
}

a.color-merieux-light-blue:hover {
  color: $color-merieux-extra-light-blue;
}

a.color-merieux-red:hover {
  color: $color-merieux-extra-light-red;
}

a.color-merieux-cyan:hover {
  color: $color-merieux-extra-light-cyan;
}

a.color-merieux-green:hover {
  color: $color-merieux-extra-light-green;
}
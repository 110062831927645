// Utility classes

$browser-context: 16;
@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

$spacer: 5px;

$padding-1: 5px;
$padding-2: 15px;
$padding-3: 25px;
$padding-4: 35px;
$padding-5: 45px;

$spacer-1: $spacer * 1;
$spacer-2: $spacer * 2;
$spacer-3: $spacer * 3;
$spacer-4: $spacer * 5;
$spacer-5: $spacer * 7;

[data-component='sidebar'] {
  .p-1 {
    padding: $padding-1 !important;
  }

  .p-2 {
    padding: $padding-2 !important;
  }

  .p-3 {
    padding: $padding-3 !important;
}

.p-4 {
  padding: $padding-4 !important;
}

.p-5 {
  padding: $padding-5 !important;
}

//left
.pl-1 {
  padding-left: $padding-1 !important;
}

.pl-2 {
  padding-left: $padding-2 !important;
}

.pl-3 {
  padding-left: $padding-3 !important;
}

.pl-4 {
  padding-left: $padding-4 !important;
}

.pl-5 {
  padding-left: $padding-5 !important;
}

//right
.pr-1 {
  padding-right: $padding-1 !important;
}

.pr-2 {
  padding-right: $padding-2 !important;
}

.pr-3 {
  padding-right: $padding-3 !important;
}

.pr-4 {
  padding-right: $padding-4 !important;
}

.pr-5 {
  padding-right: $padding-5 !important;
}

//top
.pt-1 {
  padding-top: $padding-1 !important;
}

.pt-2 {
  padding-top: $padding-2 !important;
}

.pt-3 {
  padding-top: $padding-3 !important;
}

.pt-4 {
  padding-top: $padding-4 !important;
}

.pt-5 {
  padding-top: $padding-5 !important;
}

//bottom
.pb-1 {
  padding-bottom: $padding-1 !important;
}

.pb-2 {
  padding-bottom: $padding-2 !important;
}

.pb-3 {
  padding-bottom: $padding-3 !important;
}

.pb-4 {
  padding-bottom: $padding-4 !important;
}

.pb-5 {
  padding-bottom: $padding-5 !important;
}

//left & right
.px-1 {
  padding-left: $padding-1 !important;
  padding-right: $padding-1 !important;
}

.px-2 {
  padding-left: $padding-2 !important;
  padding-right: $padding-2 !important;
}

.px-3 {
  padding-left: $padding-3 !important;
  padding-right: $padding-3 !important;
}

.px-4 {
  padding-left: $padding-4 !important;
  padding-right: $padding-4 !important;
}

.px-5 {
  padding-left: $padding-5 !important;
  padding-right: $padding-5 !important;
}

//top & bottom
.py-1 {
  padding-top: $padding-1 !important;
  padding-bottom: $padding-1 !important;
}

.py-2 {
  padding-top: $padding-2 !important;
  padding-bottom: $padding-2 !important;
}

.py-3 {
  padding-top: $padding-3 !important;
  padding-bottom: $padding-3 !important;
}

.py-4 {
  padding-top: $padding-4 !important;
  padding-bottom: $padding-4 !important;
}

.py-5 {
  padding-top: $padding-5 !important;
  padding-bottom: $padding-5 !important;
}

.m-1 {
  margin: $spacer-1 !important;
}

.m-2 {
  margin: $spacer-2 !important;
}

.m-3 {
  margin: $spacer-3 !important;
}

.m-4 {
  margin: $spacer-4 !important;
}

.m-5 {
  margin: $spacer-5 !important;
}

//left
.ml-1 {
  margin-left: $spacer-1 !important;
}

.ml-2 {
  margin-left: $spacer-2 !important;
}

.ml-3 {
  margin-left: $spacer-3 !important;
}

.ml-4 {
  margin-left: $spacer-4 !important;
}

.ml-5 {
  margin-left: $spacer-5 !important;
}

//right
.mr-1 {
  margin-right: $spacer-1 !important;
}

.mr-2 {
  margin-right: $spacer-2 !important;
}

.mr-3 {
  margin-right: $spacer-3 !important;
}

.mr-4 {
  margin-right: $spacer-4 !important;
}

.mr-5 {
  margin-right: $spacer-5 !important;
}

//top
.mt-1 {
  margin-top: $spacer-1 !important;
}

.mt-2 {
  margin-top: $spacer-2 !important;
}

.mt-3 {
  margin-top: $spacer-3 !important;
}

.mt-4 {
  margin-top: $spacer-4 !important;
}

.mt-5 {
  margin-top: $spacer-5 !important;
}

//bottom
.mb-1 {
  margin-bottom: $spacer-1 !important;
}

.mb-2 {
  margin-bottom: $spacer-2 !important;
}

.mb-3 {
  margin-bottom: $spacer-3 !important;
}

.mb-4 {
  margin-bottom: $spacer-4 !important;
}

.mb-5 {
  margin-bottom: $spacer-5 !important;
}

//left & right
.mx-1 {
  margin-left: $spacer-1 !important;
  margin-right: $spacer-1 !important;
}

.mx-2 {
  margin-left: $spacer-2 !important;
  margin-right: $spacer-2 !important;
}

.mx-3 {
  margin-left: $spacer-3 !important;
  margin-right: $spacer-3 !important;
}

.mx-4 {
  margin-left: $spacer-4 !important;
  margin-right: $spacer-4 !important;
}

.mx-5 {
  margin-left: $spacer-5 !important;
  margin-right: $spacer-5 !important;
}

//top & bottom
.my-1 {
  margin-top: $spacer-1 !important;
  margin-bottom: $spacer-1 !important;
}

.my-2 {
  margin-top: $spacer-2 !important;
  margin-bottom: $spacer-2 !important;
}

.my-3 {
  margin-top: $spacer-3 !important;
  margin-bottom: $spacer-3 !important;
}

.my-4 {
  margin-top: $spacer-4 !important;
  margin-bottom: $spacer-4 !important;
}

.my-5 {
  margin-top: $spacer-5 !important;
  margin-bottom: $spacer-5 !important;
}

button, a {
  .focus {
    outline: 0;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  &:focus {
    outline: 0;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
}

  .sidebar {
    position: fixed;
    z-index: 999;
  }

  //first menu
  .first-menu {
    position: fixed;
    background-color: $secondary-color;
    height: 100%;
    width: 75px;

    a {
      color: #fff;
    }

    i {
      font-size: 20px;
    }

    span {
      font-size: 14px;
      white-space: nowrap;
      font-family: 'Open Sans', sans-serif;
      opacity: 0;
      visibility: hidden;
    }

    &:hover span {
      opacity: 1;
      visibility: visible;
    }

    .list-group-item {
      border-radius: 0;

      &:hover {
        background-color: $primary-color;
      }
    }

    .de-active i {
      color: #bbb;
    }
  }

  .first-menu:hover {
    width: 75px;
  }

  .width-540:hover {
    width: 345px !important;
  }

  .list-group-item {
    font-size: 14px;
  }

  .list-group {
    border-radius: 0;
    padding-bottom: 100px;
    padding-right: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  //sub menu
  .submenu {
    position: fixed;
    left: 75px;
    width: 270px;
    top: 56px;
    background-color: $primary-color;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    z-index: 5;
    padding-bottom: 120px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    .list-group-item {
      margin-top: 8px;
    }
  }

  .first-menu li:hover .submenu {
    visibility: visible;
    opacity: 1;
  }

  //sub sub menu
  .sub-submenu {
    position: fixed;
    background-color: #fff;
    left: 385px;
    top: 56px;
    height: 100%;
    width: 195px;
    opacity: 0;
    visibility: hidden;
    a {
      color: $primary-color;
    }
    .list-group-item:hover {
      background-color: transparent;
    }
  }

  .submenu li:hover .sub-submenu {
    visibility: visible;
    opacity: 1;
  }

  .list-group-item {
    background-color: transparent;
    border: none;
    border-radius: 0;
    padding: 0.5rem 1rem;
    text-align: left;

    [class^="bi-"]::before,
    [class*=" bi-"]::before {
      display: unset !important;
    }
  }

  .arrow {
    position: fixed;
    left: 329px;
    width: 0;
    height: 0;
    border-top: 27px solid transparent;
    border-bottom: 27px solid transparent;
    border-right: 27px solid white;

    &:nth-child(3) {
      top: 20px;
    }
  }

  li.list-group-item.active {
    background-color: #06889d;
  }

  .direction-ltr {
    direction: ltr;
  }

  .direction-rtl {
    direction: rtl;
  }
}


// Media
@media (max-width: 1200px) {
  [data-component='sidebar'] {
    .first-menu {
      i {
        font-size: 18px;
      }
    }

    .list-group-item {
      padding: 0.4rem 1rem;
    }
  }
}
